// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-settings-tasks-Versioning__buttonRow--OVXkB {\n  display: flex;\n  margin-bottom: 10px;\n}\n._-ivis-core-client-src-settings-tasks-Versioning__buttonRow--OVXkB > *:not(:first-child) {\n  margin-left: 10px;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/settings/tasks/Versioning.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".buttonRow {\n  display: flex;\n  margin-bottom: 10px;\n\n  > *:not(:first-child) {\n    margin-left: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonRow": "_-ivis-core-client-src-settings-tasks-Versioning__buttonRow--OVXkB"
};
export default ___CSS_LOADER_EXPORT___;
