// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-ivis-PieChart__pie--ZIBde ._-ivis-core-client-src-ivis-PieChart__label--h5DHa {\n  font-weight: bold;\n}\n\n._-ivis-core-client-src-ivis-PieChart__legendBottom--eT7gP {\n  margin-top: 30px;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/ivis/PieChart.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;;AAYA;EACE,gBAAA;AATF","sourcesContent":[".pie {\n  .label {\n    font-weight: bold;\n  }\n}\n\n.legend {\n\n}\n\n.legendRight {\n\n}\n\n.legendBottom {\n  margin-top: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pie": "_-ivis-core-client-src-ivis-PieChart__pie--ZIBde",
	"label": "_-ivis-core-client-src-ivis-PieChart__label--h5DHa",
	"legendBottom": "_-ivis-core-client-src-ivis-PieChart__legendBottom--eT7gP"
};
export default ___CSS_LOADER_EXPORT___;
