// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-settings-tasks-List__toolbar--\\+TG3s {\n  margin-bottom: 15px;\n  display: flex;\n  justify-content: space-between;\n  background-color: #E6E9ED;\n  padding: 5px 10px;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/settings/tasks/List.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,yBAAA;EACA,iBAAA;AAFF","sourcesContent":["@import \"../../../node_modules/bootstrap/scss/_functions\";\n@import \"../../scss/variables.scss\";\n\n.toolbar {\n  margin-bottom: 15px;\n  display: flex;\n  justify-content: space-between;\n  background-color: #E6E9ED;\n  padding: 5px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "_-ivis-core-client-src-settings-tasks-List__toolbar--+TG3s"
};
export default ___CSS_LOADER_EXPORT___;
