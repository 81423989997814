// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za\\+Ph {\n  width: 100%;\n  height: 100%;\n}\n._-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za\\+Ph ._-ivis-core-client-src-ivis-SimpleBarChart__y_axisLabel--AqiRp, ._-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za\\+Ph ._-ivis-core-client-src-ivis-SimpleBarChart__x_axisLabel--j9b7j {\n  font-weight: bold;\n  fill: black;\n}\n._-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za\\+Ph ._-ivis-core-client-src-ivis-SimpleBarChart__x_axisLabel--j9b7j {\n  text-anchor: end;\n}\n._-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za\\+Ph ._-ivis-core-client-src-ivis-SimpleBarChart__y_axisLabel--AqiRp {\n  dominant-baseline: hanging;\n  text-anchor: start;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/ivis/SimpleBarChart.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AACI;EACI,iBAAA;EACA,WAAA;AACR;AAEI;EAGI,gBAAA;AAFR;AAKI;EAGI,0BAAA;EACA,kBAAA;AALR","sourcesContent":[".simpleBarChart {\n    width: 100%;\n    height: 100%;\n\n    %axisLabel {\n        font-weight: bold;\n        fill: black;\n    }\n\n    .x_axisLabel {\n        @extend %axisLabel;\n\n        text-anchor: end;\n    }\n\n    .y_axisLabel {\n        @extend %axisLabel;\n\n        dominant-baseline: hanging;\n        text-anchor: start;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simpleBarChart": "_-ivis-core-client-src-ivis-SimpleBarChart__simpleBarChart--Za+Ph",
	"y_axisLabel": "_-ivis-core-client-src-ivis-SimpleBarChart__y_axisLabel--AqiRp",
	"x_axisLabel": "_-ivis-core-client-src-ivis-SimpleBarChart__x_axisLabel--j9b7j"
};
export default ___CSS_LOADER_EXPORT___;
