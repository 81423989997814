// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-ivis-commons__cursorLine--JWsSE {\n  stroke-width: 1;\n  stroke: var(--panel-text-color, rgb(50, 50, 50));\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/ivis/commons.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gDAAA;AACF","sourcesContent":[".cursorLine {\n  stroke-width: 1;\n  stroke: var(--panel-text-color, rgb(50, 50, 50));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursorLine": "_-ivis-core-client-src-ivis-commons__cursorLine--JWsSE"
};
export default ___CSS_LOADER_EXPORT___;
