'use strict';

const Theme = {
    LIGHT: 'light',
    DARK: 'dark'
}

module.exports = {
   Theme
}
