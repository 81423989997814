// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-ivis-TimeBasedChartBase__loadingOverlay--cpdrP {\n  width: 100%;\n  height: 100%;\n  fill: var(--panel-background-color-secondary, #e5e5e5);\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/ivis/TimeBasedChartBase.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sDAAA;AACF","sourcesContent":[".loadingOverlay {\n  width: 100%;\n  height: 100%;\n  fill: var(--panel-background-color-secondary,#e5e5e5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingOverlay": "_-ivis-core-client-src-ivis-TimeBasedChartBase__loadingOverlay--cpdrP"
};
export default ___CSS_LOADER_EXPORT___;
