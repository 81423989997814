'use strict';

import em from '../../ivis-core/client/src/lib/extension-manager';
import React from 'react';
import {NavLink} from "../../ivis-core/client/src/lib/page";
import Share from '../../ivis-core/client/src/shares/Share';

em.set('app.title', 'Ivis for ExtremeXP');

require('../../ivis-core/client/src/root-trusted');

