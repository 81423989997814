// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-workspaces-sample-styles__titleAndGraph--Y-s2k {\n  break-after: page;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/workspaces/sample-styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".titleAndGraph {\n  break-after: page;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAndGraph": "_-ivis-core-client-src-workspaces-sample-styles__titleAndGraph--Y-s2k"
};
export default ___CSS_LOADER_EXPORT___;
