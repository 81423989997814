// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../client/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-ivis-core-client-src-settings-templates-Output__label--q4B7n {\n  font-weight: bold;\n  margin-top: 15px;\n  margin-bottom: 5px;\n}\n\n._-ivis-core-client-src-settings-templates-Output__label--q4B7n:first-child {\n  margin-top: 0px;\n}", "",{"version":3,"sources":["webpack://./../ivis-core/client/src/settings/templates/Output.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".label {\n  font-weight: bold;\n  margin-top: 15px;\n  margin-bottom: 5px;\n}\n\n.label:first-child {\n  margin-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "_-ivis-core-client-src-settings-templates-Output__label--q4B7n"
};
export default ___CSS_LOADER_EXPORT___;
